
import * as ipxRuntime$AKid0Yd72C from '/builds/homeloop/dev/haussmann-v2-ts/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "homeloop-static.s3.eu-west-3.amazonaws.com",
    "media.apimo.pro",
    "www.dolmen-tech.com",
    "www.leparisien.fr",
    "media-exp1.licdn.com",
    "www.varmatin.com",
    "www.appart-sens.fr",
    "upload.wikimedia.org",
    "www.leprogres.fr",
    "cdn.codepromo.fr",
    "fr.vocalepresse.com",
    "adecco-analytics.fr"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$AKid0Yd72C, defaults: {} }
}
        